import Vue from "vue";

function addZero(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

//格式化时间
Vue.filter("formatTime", function (value, type = "YMD") {
  if (!value) return null;
  var dataTime = "";
  var data = new Date(value);
  var year = data.getFullYear();
  var month = addZero(data.getMonth() + 1);
  var day = addZero(data.getDate());
  var hour = addZero(data.getHours());
  var minute = addZero(data.getMinutes());
  var second = addZero(data.getSeconds());
  if (type == "YMD") {
    dataTime = year + "-" + month + "-" + day;
  } else if (type == "YMDHMS") {
    dataTime =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  } else if (type == "HMS") {
    dataTime = hour + ":" + minute + ":" + second;
  } else if (type == "YM") {
    dataTime = year + "-" + month;
  }
  return dataTime; //将格式化后的字符串输出到前端显示
});

//订单状态status
Vue.filter("status", function (status) {
  if (status == "WAIT_PAY") return "待支付";
  else if (status == "WAIT_DELIVER") return "待发货";
  else if (status == "SHIPPED") return "待收货";
  else if (status == "COMPLETED") return "交易完成";
  else if (status == "REFUNDING") return "退款中";
  else if (status == "CLEAN") return "已取消";
  else return "";
});

//预约订单状态status
Vue.filter("yuyueStatus", function (status) {
  if (status == "0") return "预约完成";
  else if (status == "1") return "上门中";
  else if (status == "2") return "运输中";
  else if (status == "3") return "待核单";
  else if (status == "4") return "已完成";
  else if (status == "5") return "已取消";
  else return "";
});

//数据来源
Vue.filter("sourceType", function (opt) {
  if (opt == 0) return "平台自建";
  else if (opt == 1) return "后台导入";
});

//性别
Vue.filter("sex", function (opt) {
  if (opt == 0) return "男";
  else if (opt == 1) return "女";
});

//价格
Vue.filter("money", function (opt) {
  if (!opt) return "0.00";
  return (parseFloat(opt) / 100).toFixed(2);
});
