import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Home from "../views/index.vue";

Vue.use(VueRouter);

//解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      name: "登录",
    },
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/index",
    component: Home,
    children: [
      {
        path: "/user/index",
        name: "user",
        component: () => import("@/views/user/index"),
        meta: {
          name: "用户列表",
          isMenu: true,
        },
      },
      {
        path: "/goods/index",
        name: "goods",
        component: () => import("@/views/goods/index"),
        meta: {
          name: "商品列表",
          isMenu: true,
        },
      },
      {
        path: "/orderList/index",
        name: "orderList",
        component: () => import("@/views/orderList/index"),
        meta: {
          name: "商品订单",
          isMenu: true,
        },
      },
      {
        path: "/recoveryOrder/index",
        name: "recoveryOrder",
        component: () => import("@/views/recoveryOrder/index"),
        meta: {
          name: "回收订单",
          isMenu: true,
        },
      },
      {
        path: "/classify/index",
        name: "classify",
        component: () => import("@/views/classify/index"),
        meta: {
          name: "商品分类列表",
          isMenu: true,
        },
      },
      {
        path: "/publicActive/index",
        name: "publicActive",
        component: () => import("@/views/publicActive/index"),
        meta: {
          name: "公益项目列表",
          isMenu: true,
        },
      },
      {
        path: "/hotActive/index",
        name: "hotActive",
        component: () => import("@/views/hotActive/index"),
        meta: {
          name: "热门活动列表",
          isMenu: true,
        },
      },
      {
        path: "/carousel/index",
        name: "carousel",
        component: () => import("@/views/carousel/index"),
        meta: {
          name: "轮播列表",
          isMenu: true,
        },
      },
      {
        path: "/recoveryList/index",
        name: "recoveryList",
        component: () => import("@/views/recoveryList/index"),
        meta: {
          name: "回收分类列表",
          isMenu: true,
        },
      },
      {
        path: "/paramsConfig/index",
        name: "paramsConfig",
        component: () => import("@/views/paramsConfig/index"),
        meta: {
          name: "参数配置列表",
          isMenu: true,
        },
      },
      {
        path: "/taskList/index",
        name: "taskList",
        component: () => import("@/views/taskList/index"),
        meta: {
          name: "任务列表",
          isMenu: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
